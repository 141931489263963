<template>
  <base-layout class="wallet-card-view">
    <!-- 不要了 -->
    <!-- <div class="text">请填写真实信息，信息提交后无法修改</div> -->

    <van-form @submit="onSubmit">
      <van-field
        v-model="form.memberName"
        name="姓名"
        label="姓名"
        placeholder="请输入姓名"
        readonly
        :rules="[{ required: true, message: '请输入姓名' }]"
      />
      <van-field
        model-value="身份证"
        name="证件类型"
        label="证件类型"
        placeholder="请输入证件类型"
        readonly
        :rules="[{ required: true, message: '请输入证件类型' }]"
      />
      <van-field
        v-model="form.idCard"
        name="证件号"
        label="证件号"
        placeholder="请输入证件号"
        maxlength="18"
        readonly
        :rules="[{ required: true, message: '请输入证件号' }]"
      />

      <van-field
        class="m-t-10"
        v-model="form.bankCardNumber"
        name="银行卡号"
        label="银行卡号"
        placeholder="请输入银行卡号"
        maxlength="19"
        :rules="[{ required: true, message: '请输入银行卡号' }]"
      />
      <van-field
        v-model="form.bankAccountName"
        name="开户行"
        label="开户行"
        placeholder="请输入开户行"
        :rules="[{ required: true, message: '请输入开户行' }]"
        readonly
      />
      <van-field
        v-model="form.bankAccountBranchName"
        name="开户支行"
        label="开户支行"
        placeholder="请输入开户支行"
        maxlength="30"
        :rules="[{ required: true, message: '请输入开户支行' }]"
      />

      <div class="text">请填写您开户的银行卡信息，务必保证账号信息准确，否则佣金无法到账~如需帮助请拨打4000761661</div>

      <div class="btn-box">
        <van-button type="success" block native-type="submit" :disabled="hasSubmitDisabled">提交</van-button>
      </div>
    </van-form>
  </base-layout>
</template>

<script>
import { selectBank, bindingBank, updateBank } from "@/api/index";
import { Toast } from "@/utils/ui";

export default {
  data() {
    return {
      form: {
        id: undefined,
        memberName: "", // 姓名
        idCard: "", // 证件号

        bankCardNumber: "", // 银行卡号
        bankAccountName: "中国银行", // 开户行
        bankAccountBranchName: "" // 开户支行
      },
      bakForm: undefined
    };
  },

  computed: {
    hasSubmitDisabled() {
      if (!this.bakForm) {
        return true;
      }
      return this.bakForm === JSON.stringify(this.form);
    }
  },

  async created() {
    document.title = "银行卡绑定";
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      try {
        const { data } = await selectBank();
        this.form = data || {};

        if (!this.form.bankAccountName) {
          this.form.bankAccountName = "中国银行";
        }

        this.bakForm = JSON.stringify(this.form);
      } catch (error) {
        // TODO: 异常场景待处理
        console.error("error:", error);
      }
    },

    async onSubmit() {
      if (this.form.id) {
        // 新增
        const { code } = await updateBank(this.form);
        if (code !== 200) {
          return;
        }
        Toast.success("修改成功");
      } else {
        // 修改
        const { code } = await bindingBank(this.form);
        if (code !== 200) {
          return;
        }
        Toast.success("绑定成功");
      }

      setTimeout(() => {
        this.$router.go(-1);
      }, 1000);
    }
  }
};
</script>

<style lang="less" scoped>
.m-t-10 {
  margin-top: 10px;
}

.wallet-card-view {
  // padding: 12px;
  background-color: #f3f6f7;
}

.text {
  padding: 10px 16px;
  line-height: 20px;
  font-size: 14px;
  color: #969696;
}

.btn-box {
  padding: 10px 16px 0;
}
</style>
